import * as React from "react";
import { Link as GatsbyLink } from "gatsby";

import { CBTNLogoMono } from "./Illustrations/Logo";

export const Link = ({ className = "", children, href }) =>
  href.startsWith("/") ? (
    <GatsbyLink to={href} className={className}>
      {children}
    </GatsbyLink>
  ) : (
    <a href={href} target="_blank" rel="noreferrer" className={className}>
      {children}
    </a>
  );

export const Header = ({ children }) => (
  <h3 className="text-sm font-semibold text-navy-300 tracking-wider uppercase">
    {children}
  </h3>
);

export const FooterLink = ({ className = "", children, href }) => (
  <Link
    href={href}
    className={`text-base text-navy-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-4 focus:ring-offset-navy-800 focus:ring-navy-100 focus:text-white focus:bg-navy-800 focus:rounded-full ${className}`}
  >
    {children}
  </Link>
);

export const List = ({ items }) => (
  <ul className="mt-4 space-y-4">
    {items.map(({ title, href }) => (
      <li key={title}>
        <FooterLink href={href}>{title}</FooterLink>
      </li>
    ))}
  </ul>
);

export const Footer = () => (
  <footer className="bg-navy-800" aria-labelledby="footerHeading">
    <h2 id="footerHeading" className="sr-only">
      Footer
    </h2>
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <div className="pb-8 md:grid md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 xl:gap-8">
        <div className="mb-8">
          <Header>About</Header>
          <List
            items={[
              { title: "What is CBTN?", href: "/about-us" },
              { title: "Member Institutions", href: "/institutions" },
              {
                title: "Research Collaborators",
                href: "/research-partners",
              },
              {
                title: "Frequently Asked Questions",
                href: "/frequently-asked-questions",
              },
              {
                title: "Help Desk",
                href: "/help",
              },
              {
                title: "Privacy Policies",
                href: "/privacy-policies",
              },
            ]}
          />
        </div>
        <div className="mb-8">
          <Header>Discover</Header>
          <List
            items={[
              { title: "Scientific Projects", href: "/projects" },
              { title: "Brain Tumor Types", href: "/brain-tumor-types" },
              {
                title: "Publications",
                href: "/publications",
              },
              {
                title: "Presentations",
                href: "/presentations",
              },
              {
                title: "Informatics Portals",
                href: "/informatics-portals",
              },
              {
                title: "Research Resources",
                href: "/research-resources",
              },
              {
                title: "Pediatric Brain Tumor Atlas",
                href: "/pediatric-brain-tumor-atlas",
              },
            ]}
          />
        </div>
        <div>
          <Header>Partner</Header>
          <List
            items={[
              {
                title: "Gift From a Child Initiative",
                href: "/gift-from-a-child-initiative",
              },
              {
                title: "Research Partnerships",
                href: "/research-partnerships",
              },
              {
                title: "Foundation and Family Partnerships",
                href: "/partnerships",
              },
              {
                title: "Newsroom",
                href: "/news",
              },
              {
                title: "Newsletter Archive",
                href:
                  "https://us13.campaign-archive.com/home/?u=0a6b1a2720d2b815ed2b94f24&id=5b7c618345",
              },
              {
                title: "Annual Report",
                href: "/annual-reports",
              },
            ]}
          />
        </div>
        <div className="md:text-right xl:col-start-5">
          <div className="w-60 text-navy-50">
            <CBTNLogoMono />
          </div>
        </div>
      </div>
      <div className="border-t border-navy-700 pt-8 space-y-4">
        <div className="sm:mr-6">
          <Header>Subscribe to our newsletter</Header>
          <p className="mt-2 text-base text-gray-300">
            The latest news, articles, and resources, sent to your inbox.
          </p>
        </div>
        <a
          href="https://mailchi.mp/64714d90e4d3/cbttcnewsletter"
          target="_blank"
          rel="noreferrer"
          className="font-jaf w-full sm:w-36 border border-transparent rounded-full py-2 px-0 flex items-center justify-center text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-4 bg-navy-600 hover:bg-navy-700 focus:bg-navy-700 focus:ring-offset-navy-700 focus:ring-white"
        >
          Subscribe
        </a>
      </div>
      <div className="mt-8 border-t border-navy-700 pt-8 text-center md:flex md:items-center md:justify-between">
        <div className="flex justify-center space-x-6 md:order-2">
          <FooterLink href="https://www.facebook.com/CBTNetwork">
            <span className="sr-only">Facebook</span>
            <svg
              viewBox="0 0 24 24"
              className="h-6 w-6"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
            </svg>
          </FooterLink>

          <FooterLink href="https://www.instagram.com/childrensbraintumornetwork">
            <span className="sr-only">Instagram</span>
            <svg
              viewBox="0 0 24 24"
              className="h-6 w-6"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
              <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
              <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
            </svg>
          </FooterLink>

          <FooterLink href="https://twitter.com/CBTNetwork">
            <span className="sr-only">Twitter</span>
            <svg
              viewBox="0 0 24 24"
              className="h-6 w-6"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
            </svg>
          </FooterLink>

          <FooterLink href="https://www.youtube.com/channel/UCzoOwMGt90JvKdsWNQPCNAA">
            <span className="sr-only">YouTube</span>
            <svg
              viewBox="0 0 24 24"
              className="h-6 w-6"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"></path>
              <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02"></polygon>
            </svg>
          </FooterLink>

          <FooterLink href="mailto:info@cbtn.org">
            <span className="sr-only">Email</span>

            <svg
              viewBox="0 0 24 24"
              className="h-6 w-6"
              width="24"
              height="24"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
              <polyline points="22,6 12,13 2,6"></polyline>
            </svg>
          </FooterLink>
        </div>
        <p className="mt-8 text-base text-navy-300 md:mt-0 md:order-1">
          &copy; Children&apos;s Brain Tumor Network. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
);
