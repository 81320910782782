import * as React from "react";

export const Button = ({ className = "", as, ...rest }) => {
  const Component = as || "button";

  const size = {
    1: "px-2.5 py-1.5 text-xs font-medium",
    2: "px-3 py-2 text-sm leading-4 font-medium",
    3: "px-4 py-2 text-sm font-medium",
    4: "px-4 py-2 text-base font-medium",
    5: "px-6 py-3 text-base font-medium ",
    icon: "p-1.5 text-sm font-medium",
  }[rest.size || 3];

  const variant = {
    cta:
      "border border-navy-700 bg-navy-700 text-white hover:bg-navy-800 hover:border-navy-800 focus:bg-navy-800 focus:border-navy-800 focus:ring-navy-800",
    subtle:
      "border border-navy-100 bg-navy-50 text-navy-700 hover:bg-navy-100 hover:border-navy-100 focus:bg-navy-100 focus:border-navy-100 focus:ring-navy-800",
    overColor:
      "border-2 border-white bg-transparent text-white hover:bg-white hover:text-navy-900 focus:bg-white focus:text-navy-900 focus:ring-white focus:ring-offset-navy-700",
  }[rest.variant || "subtle"];

  return (
    <Component
      {...rest}
      className={`relative inline-flex justify-center items-center rounded-full font-jaf shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${size} ${variant} ${className}`}
    />
  );
};
