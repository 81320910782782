import * as React from "react";
import Imgix from "react-imgix";

export const Image = ({ src, imgixParams, ...rest }) => {
  if (
    src?.includes(
      "https://d3b-684194535433-us-east-1-qa-cms.s3.amazonaws.com/original_images"
    ) ||
    src?.includes("https://cbtn-s3.imgix.net")
  ) {
    return (
      <Imgix
        src={src?.replace(
          "https://d3b-684194535433-us-east-1-qa-cms.s3.amazonaws.com/original_images",
          "https://cbtn-s3.imgix.net"
        )}
        imgixParams={imgixParams}
        htmlAttributes={rest}
        {...rest}
      />
    );
  }

  return <img src={src} {...rest} />;
};
